<template>
  <div class="has-text-centered">

    <div v-if="isLoading">
      <Loading />
    </div>

    <div v-else>
      <p class="is-size-5">Home</p>
      <div class="is-flex is-justify-content-center pt-2">
        <img
        class="image is-128x128 p-0"
        src="../assets/under-construction.png"
        alt=""
        />
      </div>
      <p class="is-size-4">HI and Welcome !</p>
      <p class="is-size-5">
        Thank you for visiting our website. We apologize for any inconvenience,
        but please note that our website is currently under construction and may
        not be fully operational. We kindly ask you to check back later when we
        are ready to present our improved website. If you have any questions or
        concerns, please feel free to contact us.
      </p>
    </div>
    <div>
      <video @loadedmetadata="onVideoLoad" autoplay muted loop playsinline id="myVideo">
        <source :src="require('../assets/Test Video.mp4')" type="video/mp4">
  Your browser does not support HTML5 video.
      </video>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Loading from "../views/default/view-loading.vue";

const isLoading = ref(true);

function onVideoLoad() {
  isLoading.value = false;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

#myVideo {
  position: fixed;
  filter: brightness(0.3);
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
}
</style>
