<template>
<!DOCTYPE html>
<html>
<head>
	
</head>
<body>
	<div class="container">
		<div class="error-content">
			<h1 class="error-code">404</h1>
			<p class="error-message">Oops! The page you're looking for was not found.</p>
			<a href="/" class="error-link">Go back to Homepage</a>
		</div>
	</div>
</body>
</html>

</template>

<script setup>
import { ref } from 'vue'

const msg = ref('Hello World!')
</script>

<style scoped>

		.container {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100vh;
		}

		.error-content {
			text-align: center;
		}

		.error-code {
			font-size: 120px;
			font-weight: bold;
			color: white;
			margin-bottom: 20px;
		}

		.error-message {
			font-size: 24px;
			color: white;
			margin-bottom: 40px;
		}

		.error-link {
			display: inline-block;
			padding: 10px 20px;
			background-color: #333;
			color: #fff;
			text-decoration: none;
			border-radius: 3px;
			transition: background-color 0.3s ease;
		}

		.error-link:hover {
			background-color: #444;
		}
	</style>