<template>
  <div class="navbar-container pl-3 pr-3 pt-3 pb-2">
    <div class="logo-container pb-0 pr-0 pt-1">
      <router-link to="/">
        <img
          class=""
          :src="require('../../assets/logo.svg')"
          height="50"
          width="250"
        />
      </router-link>
    </div>
    <nav
      class="navitem-container navbar is-size-5 mt-1"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navlink-container">
        <div class="navbar-brand">
          <a
            role="button"
            class="navbar-burger"
            :class="{
              'navbar-burger is-active': hamburgerToggle,
              active: !hamburgerToggle,
            }"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            @click="onToggleBurger"
          >
            <p class="is-size-6">Menu</p>
          </a>
        </div>
      </div>

      <div
        id="navbarBasicExample"
        class="navbar-menu"
        :class="{
          'navbar-menu is-active': hamburgerToggle,
          active: !hamburgerToggle,
        }"
      >
        <div class="navbar-start is-align-content-center">
          <router-link @click="onToggleBurgerClose" to="/" class="navbar-item"
            >Home</router-link
          >

          <router-link
            @click="onToggleBurgerClose"
            to="/projects"
            class="navbar-item"
            >Projects</router-link
          >
          <router-link
            @click="onToggleBurgerClose"
            to="/tech-in-use"
            class="navbar-item"
            >Tech in use</router-link
          >
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div>
              <router-link
                @click="onToggleBurgerClose"
                to="/contact"
                class="button is-white is-medium"
              >
                <strong class="has-text-black">Get in Touch</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
// +++ Vue imports ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import { ref, onMounted } from "vue";
// +++ 3rd party imports ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ Own imports ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ Other import +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// +++ Main Logic +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ properties +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ emits ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ refs +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
const hamburgerToggle = ref(false);
const mediaWidthStatus = ref(0);
// +++ life cycle +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ watcher ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
onMounted(() => {
  
});

// +++ event handler ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
function onToggleBurger() {
  hamburgerToggle.value = hamburgerToggle.value == true ? false : true;
}

function onToggleBurgerClose() {
  hamburgerToggle.value = false;
}


// +++ member +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// +++ expose +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
</script>
<style scoped lang="scss">
.navbar-container {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.logo-container {
  align-items: center;
  width: 100%;
  background-color: transparent;
}

@media (max-width: 1024px) {
.navbar-container {
  display: block;
  justify-content: space-around;
  background-color: transparent;
}
  .logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .logo-container img {
    margin: 0 auto;
  }
}

.navitem-container {
  background-color: transparent;
  justify-content: right;
  width:100%
}

.navlink-container {
  justify-content: space-between;
}

.navbar,
.navbar-menu {
  background-color: transparent;
  color: black;
}

.navbar-item:link,
.navbar-item:active,
.navbar-item:visited {
  background-color: transparent;
  color: white;
}

.navbar-item:hover {
  background-color: white;
  color: #1a1a1a;
  border-radius: 10px;
}

.navbar-burger {
  background-color: transparent;
  color: white;
}

.navbar-brand {
  background-color: transparent;
  color: black;
}

</style>
